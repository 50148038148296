import React, { useEffect } from "react";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import { useGetPageDetQuery } from "../echron/clientapi";
import { setForm } from "../echron/formSlice";
import { useDispatch, useSelector } from "react-redux";
import Detail from "../component/Detail";
import Loader from "../component/Loader";
import Listing from "../component/Listing";
import Meta from "../component/Meta";
import { setIsLoad, selectLoad } from "../echron/navSlice"
import Loader_route from "../component/Loader_route"
import Aboutinner from '../component/Aboutinner'
// import Search from "../component/Search";


function Page() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  document.getElementById("echron-root").classList.add("echron-inner-header");
  const path = useLocation();
  const finalPath = path.pathname.slice(1);
  const pageRes = useGetPageDetQuery(finalPath);
  const dispatch = useDispatch();
	const isLoad = useSelector(selectLoad);
	const NavigationType = useNavigationType();

  useEffect(() => {
    if (pageRes.isSuccess) {
      const status = pageRes.data.status;
      const detail = status.data;
      dispatch(setForm({ page: detail.name }));
    }
    if(NavigationType !== "POP") {
			dispatch(setIsLoad(true));
			setTimeout(function() {
				dispatch(setIsLoad(false));
			}, 750)
		}
  }, [dispatch, pageRes]);

  
  
  if (isLoad) return <Loader_route />

  if (pageRes.isLoading) return <Loader mtop="100px" />
  if (pageRes.isError) return <h1>An Error occured {pageRes.error.error}</h1>
  if (pageRes.isSuccess) {
   
    if (pageRes.data.status === "404") {
      return window.location.href = '/404';
    } else {
      const status = pageRes.data.status;
      const detail = status.data;
      const page = pageRes.data.status.page;
      
      const meta = (
        <Meta
          title={detail.m_title}
          desc={detail.m_description}
          cano={finalPath}
          follow={0}
        />
      );

      let cat_id;
      let loc_id;
      let dev_id;
      let pag_id;

      if (page === "location") {
        loc_id = detail.id;
        cat_id = 0;
        dev_id = 0;
        pag_id = 0;
      }
      else if (page === "category") {
        cat_id = detail.id;
        loc_id = 0;
        dev_id = 0;
        pag_id = 0;
      }
      else if (page === "developer") {
        cat_id = 0;
        loc_id = 0;
        dev_id = detail.id;
        pag_id = 0;
      }
      else if (page === "page") {
        cat_id = 0;
        loc_id = 0;
        dev_id = 0;
        pag_id = detail.id;
      }
      else {
        dev_id = 0;
        loc_id = 0;
        cat_id = 0;
        pag_id = 0;
      }

      if (page === "post") {
        return (
          <>
            {meta}
            <Detail
              id={detail.id}
              name={detail.name}
              description={detail.description}
              address={detail.address}
              price={detail.amount}
              price_m={detail.max_amount}
              bedroom={detail.bedroom}
              bathroom={detail.bathroom}
              furnished={detail.furnished}
              total_floor={detail.total_floor}
              area={detail.area}
              area_m={detail.max_area}
              year_built={detail.year_built}
              p_for={detail.p_for}
              maintance={detail.maintance}
              parking={detail.parking}
              map={detail.map}
              gallery={detail.gallery}
              banner={detail.image}
              categroy={detail.categroy}
              banner_cat={detail.banner_cat}
              rera={detail.rera}
              specification_arr={detail.specification_arr}
              location={detail.location}
              amenity={detail.amenity}
              payment_term={detail.payment_term}
              p_type={detail.p_type}
              n_name={detail.n_name}
              n_description={detail.n_description}
              developerlogo={detail.developer}
              thumb={detail.thumb}
            />
          </>
        );
      }
      else if (page === "category" || page === "location" || page === "developer") {
        return (
          <>
            {meta}
            <Listing
              cat_id={cat_id}
              loc_id={loc_id}
              dev_id={dev_id}
              pag_id={pag_id}
              parent_list={status.parent_list}
              property_list={status.property_list}
              name={detail.name}
              img2={detail.image2}
              // thumb={detail.thumb.thumbImage}
              page={page}
              developer={detail.developer}
            />
          </>
        );
      }
      else if (page === "page") {
        if(detail.id=='1' || detail.id=='2' || detail.id=='3' || detail.id=='4' || detail.id=='5') {
          return (
            <>
              {meta}
              <Listing
                cat_id={cat_id}
                loc_id={loc_id}
                dev_id={dev_id}
                pag_id={pag_id}
                parent_list={status.parent_list}
                property_list={status.property_list}
                name={detail.name}
                img2={detail.image2}
                // thumb={detail.thumb.thumbImage}
                page={page}
                developer={detail.developer}
              />
            </>
          );
        }

        else if (page === "page") {
          if( detail.id=='6' ) 
            return(
          <>
          {meta}
            <Aboutinner
            name={detail.name} 
            img2={detail.image2} 
            />
          </>
          );
        }
        else {
          return (
            <>
              {meta}
              <h1><br /><br />To be designed <br />Page: {page}<br />Name: {detail.name}</h1>
            </>
          );
        }
      }
    
      else {
        return (
          <>
            <h1>Page: {page}</h1>
            <h2>Name: {detail.name}</h2>
          </>
        );
      }
      // if(page === "page"){
      //   if( detail.id =='8'){
      //     return (
      //       <Search />
      //     )
      //   }   
      // }
  
    }
  }
}

export default Page;