import React, { useEffect, useState, useMemo } from 'react';
import { useGetSearchPostQuery } from './echron/clientapi';
import Loader from './component/Loader';
import Meta from './component/Meta';
import Filter_List from './component/Filter_List';

const Search = () => {

  window.scrollTo({
    top: 500,
    behavior: 'smooth'
  });

  const [apartments, setApartments] = useState([]);
  const { data, isError, isLoading } = useGetSearchPostQuery();
  
  useEffect(() => {
    if (data) {
      setApartments(data.post);
    }
    
  }, [data]);

 
  const getNormalizedSearchTerm = () => {
    const path = window.location.pathname;
    const match = path.match(/\/search\/(.+)/);
   
    if (match) {
      return normalizeText(decodeURIComponent(match[1]));
    }
    return '';
  };

  const normalizeText = (text) => {
    return text
      .toLowerCase()
      .replace(/\s+/g, ' ') 
      .replace(/[^\w\s]/g, ' ') 
      .trim();
  };
  

  const filterDataFromUrl = useMemo(() => {

    const normalizedSearchTerm = getNormalizedSearchTerm();
   
    // Replace "bhk" search term
    const bhkReplacements = {
      '2 bhk': '2bhk',
      '3 bhk': '3bhk',
      '4 bhk': '4bhk',
      '5 bhk': '5bhk',
      '6 bhk': '6bhk',
      'in': '',
      'and':'',
      'nh8': 'nh 8',
      'property':'',
      'gurugram':'gurgaon',
    };
    
    let modifiedQuery = normalizedSearchTerm;
    
    Object.keys(bhkReplacements).forEach(key => {
      modifiedQuery = modifiedQuery.replace(new RegExp(key, 'g'), bhkReplacements[key]);
    });
    
    const searchWords = modifiedQuery.split(' ').filter(word => word.trim().length > 0);

    const searchWord2nd = normalizedSearchTerm.split(' ').filter(word => word.trim().length > 0);
    
    if (apartments.length === 0 || searchWords.length === 0) return [];

    // search all data
    const apartmentMatchesAllTerms = (apartment) => {
          
      if (searchWords[0] === "voice") {
        let combinedText = (
          (apartment.name || '') + ' ' +
          (apartment.n_name || '') + ' ' +
          (apartment.categroy_name || '') + ' ' +
          (apartment.location || '') + ' ' +
          (apartment.categroy || '') + ' ' +
          (apartment.developer?.name || '') + ' ' +
          (apartment.bedroom ? apartment.bedroom.split(',').map(b => b.trim() + 'bhk').join(' ') : '') + ' ' +
          (apartment.amount || '') + ' ' +
          (apartment.max_amount || '') + ' ' +
          (apartment.area ? `${apartment.area} SQ FT` : '') + ' ' +
          (apartment.p_for || '') + ' ' +
          (apartment.furnished || '') + ' ' +
          (apartment.p_type || '') 
        );
        
        const normalizedCombinedTextVoice = normalizeText(combinedText);

        const matchingdata = searchWords.slice(1).every(term => normalizedCombinedTextVoice.includes(normalizeText(term)));
        return matchingdata;
        
      } else {
        let combinedTexts = apartment.taglines || '';
        const normalizedCombinedText = normalizeText(combinedTexts);
        return searchWord2nd.every(word => normalizedCombinedText.includes(normalizeText(word)));
      }
    };
    

    // Return filtered apartments
    return apartments.filter(apartment => apartmentMatchesAllTerms(apartment));
  }, [apartments]);

  if (isLoading) {
    return <div style={{ margin: '2rem 0' }}><Loader /></div>;
  }

  if (isError) {
    return <p style={{ textAlign: 'center', margin: '2rem 0' }}>Error: Data not fetched...</p>;
  }

  let datas = []; 

  if (window.location.pathname === "/search/") {
      datas = apartments; 
  } else {
      datas = filterDataFromUrl;
  }
   

  return (
    <div>
      <Meta follow="0" />
      {
        <Filter_List  data={datas}/>
      }
      
    </div>
  );
};

export default Search;
