import React from 'react'
import Banner from '../component/Banner'
import Property_grid from './Property_grid'
import Add from '../component/Add'
import Lease from '../component/Lease'
import Developers from '../component/Developers'
import FootLinks from '../component/FootLinks'
import List from '../component/List'
import '../assets/css/Listing.css'
function Listing(props) {
    let list, page;
    if(props.parent_list) {
        if(props.pag_id=='5') {
            page = 'location'
        }
        else {
            page = props.page 
        }
        list = <List loc_id={props.loc_id} cat_id={props.cat_id} pag_id={props.pag_id}  name={props.name} page={page} />
    }
    else {
        list = (props.property_list)? <Property_grid cat_id={props.cat_id}  loc_id={props.loc_id} dev_id={props.dev_id} pag_id={props.pag_id} /> : <>
            <div class="text-center m-5">
                <h1 class="fw-bold">Coming Soon... </h1>
                {/* <div class="col-lg-6 mx-auto">
                    <p class="lead mb-4">Quickly design and customize responsive mobile-first sites with Bootstrap, the world’s most popular front-end open source toolkit, featuring Sass variables and mixins, responsive grid system, extensive prebuilt components, and powerful JavaScript plugins.</p>
                    <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
                        <a href='#' class="btn btn-primary btn-lg px-4 me-sm-3">Home</a>
                        <a href='#' class="btn btn-outline-secondary btn-lg px-4">Back</a>
                    </div>
                </div> */}
            </div>
        </>
    }
    return (
        <>

            <Banner img2={props.img2} />
           
            {/* <section className="echron-bar-item">
                <Container>
                    <ul>
                        <li className="">
                            <select name="dropdown" id="dropdown">
                                <option value="Bedroom">Bedrooms</option>
                                <option value="Bedroom">3 &amp; 4 BHK</option>
                                <option value="Bedroom">1+1 Bedroom</option>
                                <option value="Bedroom">2 &amp; 3 Bhk</option>
                            </select>
                            <div className="icon">
                                <i className="fa fa-television" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="">
                            <select name="dropdown" id="dropdown">
                                <option value="Bedroom"> Property Type</option>
                                <option value="Bedroom">Rent</option>
                                <option value="Bedroom">Purchase</option>
                                <option value="Bedroom">Sale</option>
                            </select>
                            <div className="icon">
                                <i className="fa fa-university" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="">
                            <select name="dropdown" id="dropdown">
                                <option value="Bedroom">Budget</option>
                                <option value="Bedroom">66.30 Lakh</option>
                                <option value="Bedroom">Call For Price</option>
                                <option value="Bedroom">20k</option>
                            </select>
                            <div className="icon">
                                <i className="fa fa-inr" aria-hidden="true"></i> 
                            </div>
                        </li>
                        <li className="">
                            <select name="dropdown" id="dropdown">
                                <option value="Bedroom">Prosession </option>
                                <option value="Bedroom">3 &amp; 4 BHK</option>
                                <option value="Bedroom">1+1 Bedroom</option>
                                <option value="Bedroom">2 &amp; 3 Bhk</option>
                            </select>
                            <div className="icon">
                                <i className="fa fa-cogs" aria-hidden="true"></i>  
                            </div>
                        </li>
                        <li className="">
                            <select name="dropdown" id="dropdown">
                                <option value="Bedroom">More Filters</option>
                                <option value="Bedroom">3 &amp; 4 BHK</option>
                                <option value="Bedroom">1+1 Bedroom</option>
                                <option value="Bedroom">2 &amp; 3 Bhk</option>
                            </select>
                            <div className="icon">
                                <i className="fa fa-sliders" aria-hidden="true"></i>   
                            </div>
                        </li>
                        <li>Show map <label className="switch">
                            <input type="checkbox"></input>
                            <span className="slider round"></span>
                            </label>
                        </li>
                    </ul>
                </Container>
            </section> */}
            
            <section className='EchronCards'>
            {list}
            </section>
            <Add />
            <Lease />
            <Developers />
            <FootLinks />
        </>
    )
}
export default Listing