import React, { useState, useEffect, useCallback } from "react";
import "../assets/css/Filter_List.css";
import Banner from "./Banner";
import { Link, useLocation } from "react-router-dom";
import { useGetOthersQuery, useGetLocsQuery } from "../echron/clientapi";
import { useGetDevsQuery } from "../echron/clientapi";


const Filter_List = (props) => {
  
  const locsRes = useGetOthersQuery(
    {
      type: 'location', 
      chain: '2', 
      parent_id: 'null'}
  );

  const devsRes = useGetDevsQuery();

  const [showAll, setShowAll] = useState(false);
  const [sortedData, setSortedData] = useState(props.data);
  const [saleRentFilter, setSaleRentFilter] = useState(null);
  const [bedroomFilter, setBedroomFilter] = useState([]);
  const [propertyTypeFilter, setPropertyTypeFilter] = useState([]);

  const [minpriceFilter, setMinPriceFilter] = useState(50);
  const [maxpriceFilter, setMaxPriceFilter] = useState(600000000);

  const [selectedLocations, setSelectedLocations] = useState([]);
  const [sublocationId, setSublocationId] = useState([]);
  const [sublocation, setSublocation] = useState([]);
  const [devloper, setDevloper] = useState([]);
  const [status, setStatus] = useState([]);
  const [activeBHK, setActiveBHK] = useState([]);
  const [activeProperty, setActiveProperty] = useState([]);
  const [activeStatus, setActiveStatus] = useState([]);

  const locQrs = useGetLocsQuery({
    loc_id: sublocationId,
    cat_id: null,
    page: "location",
  });

  useEffect(() => {
    filterAndSortData();
  }, [
    props.data,
    saleRentFilter,
    bedroomFilter,
    propertyTypeFilter,
    minpriceFilter,
    maxpriceFilter,
    selectedLocations,
    sublocationId,
    sublocation,
    devloper,
    status,
  ]);

  const filterAndSortData = () => {
    var filteredData = [...props.data];

    // Apply Sale Rent filter
    if (saleRentFilter) {
      filteredData = filteredData.filter(
        (item) => item.p_for == saleRentFilter
      );
    }

    // Apply bedroom filter
    if (bedroomFilter) {
      if (Array.isArray(bedroomFilter)) {
        if (bedroomFilter.length > 0) {
          filteredData = filteredData.filter(
            (item) =>
              item.bedroom &&
              bedroomFilter.some((filter) =>
                item.bedroom.split(",").includes(filter.toString())
              )
          );
        }

        filteredData = filteredData.sort((a, b) => {
          const aBedrooms = a.bedroom.split(",").map(Number);
          const bBedrooms = b.bedroom.split(",").map(Number);
          return Math.max(...bBedrooms) - Math.max(...aBedrooms);
        });
      } else if (bedroomFilter === 6) {
        filteredData = filteredData.filter(
          (item) =>
            item.bedroom && item.bedroom.split(",").some((b) => parseInt(b) > 5)
        );

        filteredData = filteredData.sort((a, b) => {
          const aBedrooms = a.bedroom.split(",").map(Number);
          const bBedrooms = b.bedroom.split(",").map(Number);
          return Math.max(...bBedrooms) - Math.max(...aBedrooms);
        });
      } else {
        filteredData = filteredData.filter(
          (item) =>
            item.bedroom &&
            item.bedroom.split(",").includes(bedroomFilter.toString())
        );

        filteredData = filteredData.sort((a, b) => {
          const aBedrooms = a.bedroom.split(",").map(Number);
          const bBedrooms = b.bedroom.split(",").map(Number);
          return Math.max(...bBedrooms) - Math.max(...aBedrooms);
        });
      }
    } else {
      filteredData = filteredData.sort((a, b) => {
        const aBedrooms = a.bedroom.split(",").map(Number);
        const bBedrooms = b.bedroom.split(",").map(Number);
        return Math.max(...bBedrooms) - Math.max(...aBedrooms);
      });
    }

    // Apply property type filter
    if (propertyTypeFilter && propertyTypeFilter.length > 0) {
      filteredData = filteredData.filter((item) => {
        const category = item.categroy_name;
        return propertyTypeFilter.includes(category);
      });
    }
    
    // Apply Price filter 
    if (minpriceFilter !== undefined && maxpriceFilter !== undefined) {
      filteredData = filteredData.filter((item) => {
        return item.amount >= minpriceFilter && item.amount <= maxpriceFilter;
      });
    }


    if (selectedLocations.length > 0) {
      filteredData = filteredData.filter((item) => {
        const itemLocation = item.location[1].toLowerCase();
        return selectedLocations.toLowerCase() === itemLocation;
      });
    }

    if (sublocation.length > 0) {
      filteredData = filteredData.filter((item) => {
        const itemsbLocation = item.location[2].toLowerCase();
        return itemsbLocation === sublocation.toLowerCase();
      });
    }

    if (devloper.length > 0) {
      filteredData = filteredData.filter((item) => {
        const itemDeveloper = item.developer.name;
        return itemDeveloper === devloper;
        //return devloper.some(selectedDev => selectedDev == itemDeveloper);
      });
    }

    if (status && status.length > 0) {
      filteredData = filteredData.filter((item) => {
        const furnished = item.furnished;
        return status.includes(furnished);
      });
    }

    // Sort data
    const sortedArray = filteredData.sort((a, b) => a.amount - b.amount);
    setSortedData(sortedArray);
  };

  const handleLocation = (name, id) => {
    setSelectedLocations(name);
    setSublocationId(id);
  };

  const handleSublok = (name) => {
    setSublocation(name);
  };

  const handleSaleRent = (rs) => {
    setSaleRentFilter(rs);
  };

  // Function to handle bedroom filter selection

  const bedroomHandle = (bedroomCount) => {
    setBedroomFilter((prevSelected) => {
      if (prevSelected.includes(bedroomCount)) {
        return prevSelected.filter((count) => count !== bedroomCount);
      } else {
        return [...prevSelected, bedroomCount];
      }
    });

    setActiveBHK((prevSelected) => {
      if (prevSelected.includes(bedroomCount)) {
        return prevSelected.filter((count) => count !== bedroomCount);
      } else {
        return [...prevSelected, bedroomCount];
      }
    });
  };

  const handlePropertyType = (propertyType) => {
    const propertyTypeArray = Array.isArray(propertyType)
      ? propertyType
      : [propertyType];

    setPropertyTypeFilter((prevFilter) => {
      const updatedFilter = new Set([...prevFilter, ...propertyTypeArray]);
      return Array.from(updatedFilter);
    });

    setActiveProperty((prevActiveProperty) => {
      const updatedActiveProperty = new Set([
        ...prevActiveProperty,
        ...propertyTypeArray,
      ]);
      return Array.from(updatedActiveProperty);
    });
  };

  // Function to handle sorting based on the selected filter
  const handleSort = (event) => {
    const { value } = event.target;
    let sortedArray = [...sortedData];

    if (value === "low") {
      sortedArray.sort((a, b) => a.amount - b.amount);
    } else if (value === "high") {
      sortedArray.sort((a, b) => b.amount - a.amount);
    }

    setSortedData(sortedArray);
  };

  //Handle Devloper filter
  const handleDelvoper = (name) => {
    setDevloper(name);
  };

  //status filter
  const handlieProStatus = (status) => {
    const propertystatus = Array.isArray(status) ? status : [status];

    setStatus((prevFilter) => {
      const updatedFilter = new Set([...prevFilter, ...propertystatus]);
      return Array.from(updatedFilter);
    });

    setActiveStatus((prevFilter) => {
      const updatedFilter = new Set([...prevFilter, ...propertystatus]);
      return Array.from(updatedFilter);
    });
  };

  const developers = devsRes?.data?.developer || [];
  const filteredDevelopers = developers.filter((post) => post.name.length > 5);
  const developersToShow = showAll
    ? filteredDevelopers
    : filteredDevelopers.slice(0, 5);

  const location = useLocation();
  const path = location.pathname;
  const searchPath = path.replace("/search/", "");
  let heading = searchPath.replace(/\+/g, " ").replace('voice&','');
  if (heading == "/search") {
    heading = "Property for Rent/Sale";
  }

  const [isShowingAll, setIsShowingAll] = useState(false);
  const itemsToShowInitially = 4;

  const items = [
    "Office Space",
    "Flats & Apartments",
    "Service Apartments",
    "Independent Kothi",
    "Penthouse",
    "Independent/Builder Floor",
    "Villas",
    "Commercial Land",
    "Factory Space",
    "Co-Working Space",
    "Industrial Land",
    "Residential Plots",
    "Commercial Retail Space",
    "Warehouse/Godown",
    "1 RK/Studio Apartments",
    "SCO/SCO Plots",
    "Cold Storage",
  ];

  const handleShowMoreClick = () => {
    setIsShowingAll(!isShowingAll);
  };

  const devs = devsRes?.data?.developer.map((itms, key) => {
    return (
      <li className={itms.name === devloper ? "activeFilter" : "dactiveFilter"}>
        <i
          class="fa-solid fa-xmark filterReset"
          onClick={(e) => {
            e.stopPropagation();
            resetFilter6();
          }}
        ></i>
        <p>{itms.name}</p>
      </li>
    );
  });

  

  let priceconvert = (val) => {
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + " Cr";
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + " Lakh";
    }
    // else if(val >= 1000) val = (val/1000).toFixed(0) + ' K';
    return val;
  };

  const LABELS = [
    50, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000,
    12000, 13000, 14000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000,
    55000, 60000, 65000, 70000, 75000, 80000, 85000, 90000, 100000, 125000,
    150000, 175000, 200000, 225000, 250000, 275000, 300000, 400000, 500000,
    600000, 700000, 800000, 900000, 1000000, 10000000, 20000000, 30000000, 40000000,
    50000000, 100000000, 200000000, 300000000, 400000000, 500000000, 600000000,
  ];

  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(LABELS.length - 1);

  const minPercent = (minValue / (LABELS.length - 1)) * 100;
  const maxPercent = (maxValue / (LABELS.length - 1)) * 100;

  useEffect(() => {
    const handleMinInput = (event) => {
      const value = Number(event.target.value);
      if (value > maxValue) {
        setMinValue(maxValue);
        setMinPriceFilter(LABELS[maxValue]);
      } else {
        setMinValue(value);
        setMinPriceFilter(LABELS[value]);
      }
    };
    // LABELS[minValue]
    const handleMaxInput = (event) => {
      const value = Number(event.target.value);
      if (value < minValue) {
        setMaxValue(minValue);
        setMaxPriceFilter(LABELS[minValue]);
      } else {
        setMaxValue(value);
        setMaxPriceFilter(LABELS[value]);
      }
    };

    const minRange = document.getElementById('minRange');
    const maxRange = document.getElementById('maxRange');

    minRange.addEventListener('input', handleMinInput);
    maxRange.addEventListener('input', handleMaxInput);

    return () => {
      minRange.removeEventListener('input', handleMinInput);
      maxRange.removeEventListener('input', handleMaxInput);
    };
  }, [minValue, maxValue]);


const [price, setPrice] = useState({
  minprice:50,
  maxprice: 600000000
});

const handleChange = (e)=>{
  setPrice({...price, [e.target.name]: e.target.value});
}

useEffect(() => {

  setMinPriceFilter(price.minprice === '' ? 50 : price.minprice);
  setMaxPriceFilter(price.maxprice === ''? 600000000 : price.maxprice);
  
}, [price]);

//Rest Value all

const resetFilterData = () => {
  setBedroomFilter([]);
  setPropertyTypeFilter([]);
  setSelectedLocations([]);
  setSublocationId([]);
  setSublocation([]);
  setDevloper([]);
  setStatus([]);
  setActiveBHK([]);
  setActiveProperty([]);
  setActiveStatus([]);
  setSaleRentFilter(null);
  setMinPriceFilter(null);
  setMaxPriceFilter(600000000);
  setMinValue([0]);
  setMaxValue(LABELS.length - 1);
  setSortedData(props.data);
};

const resetFilter0 = () => {
  setSaleRentFilter(null);
  document.querySelectorAll(".localityfilter input").forEach((x) => {
    x.checked = false;
  });
  setSortedData(props.data);
};

const resetFilter1 = (x) => {
  if (activeBHK.includes(x)) {
    const updatedActiveBHK = activeBHK.filter((item) => item !== x);
    setActiveBHK(updatedActiveBHK);
    setBedroomFilter(updatedActiveBHK);
  } else {
    console.log("Value not found in activeBHK.");
  }
};

const resetFilter2 = (x) => {
  if (x) {
    const typeUpdate = activeProperty.filter((itm) => itm !== x);
    setPropertyTypeFilter(typeUpdate);
    setActiveProperty(typeUpdate);
  }
};

const resetFilter3 = (x) => {
  if (x) {
    const statusUpdate = activeStatus.filter((itm) => itm !== x);
    setStatus(statusUpdate);
    setActiveStatus(statusUpdate);
  }
};

const resetFilter4 = () => {
  setSelectedLocations([]);
  setSublocationId([]);
  setSublocation([]);
  document.querySelectorAll(".localityfilter input").forEach((x) => {
    x.checked = false;
  });
  setSortedData(props.data);
};

const resetFilter5 = () => {
  setSublocation([]);
  setSortedData(props.data);
};

const resetFilter6 = () => {
  setDevloper([]);
  document.querySelectorAll(".localityfilter input").forEach((x) => {
    x.checked = false;
  });
  setSortedData(props.data);
};


  return (
    <>
      <Banner />
      <section className="searchingSection">
        <div className="firstBox">
          {/* first filter */}
          <div className="filter">
            <div className="filterhead">
              <h6>Applied Filters</h6>
              <button onClick={() => resetFilterData()}>Clear All</button>
            </div>
            <ul className="ulFilterlist">
              {
                <li
                  className={
                    saleRentFilter === "rent" ? "activeFilter" : "dactiveFilter"
                  }
                >
                  <i
                    class="fa-solid fa-xmark filterReset"
                    onClick={(e) => {
                      e.stopPropagation();
                      resetFilter0();
                    }}
                  ></i>
                  <p>Rent</p>
                </li>
              }

              {
                <li
                  className={
                    saleRentFilter === "sale" ? "activeFilter" : "dactiveFilter"
                  }
                >
                  <i
                    class="fa-solid fa-xmark filterReset"
                    onClick={(e) => {
                      e.stopPropagation();
                      resetFilter0();
                    }}
                  ></i>
                  <p>Sale</p>
                </li>
              }

              {bedroomFilter.map((count, index) => (
                <li
                  key={count}
                  onClick={() => bedroomHandle(count)}
                  className={count ? "activeFilter" : "dactiveFilter"}
                >
                  <i
                    className="fa-solid fa-xmark filterReset"
                    onClick={(e) => {
                      e.stopPropagation();
                      resetFilter1(count);
                    }}
                  ></i>
                  <p>{index === 5 ? "+5 More" : `${count} BHK`}</p>
                </li>
              ))}

              {items
                .slice(0, isShowingAll ? items.length : itemsToShowInitially)
                .map((item, index) => (
                  <li
                    key={index} // Add a unique key for each list item
                    onClick={() => handlePropertyType(item)}
                    className={
                      propertyTypeFilter.includes(item)
                        ? "activeFilter"
                        : "dactiveFilter"
                    }
                  >
                    <i
                      className="fa-solid fa-xmark filterReset"
                      onClick={(e) => {
                        e.stopPropagation();
                        resetFilter2(item);
                      }}
                    ></i>
                    <p>{item}</p> {/* Displaying the individual item value */}
                  </li>
                ))}

              <li
                onClick={() => {
                  handlieProStatus("Semi Furnished");
                }}
                className={
                  activeStatus.includes("Semi Furnished")
                    ? "activeFilter"
                    : "dactiveFilter"
                }
              >
                <i
                  class="fa-solid fa-xmark filterReset"
                  onClick={(e) => {
                    e.stopPropagation();
                    resetFilter3("Semi Furnished");
                  }}
                ></i>
                <p>Semi Furnished</p>
              </li>
              <li
                onClick={() => {
                  handlieProStatus("Furnished");
                }}
                className={
                  activeStatus.includes("Furnished")
                    ? "activeFilter"
                    : "dactiveFilter"
                }
              >
                <i
                  class="fa-solid fa-xmark filterReset"
                  onClick={(e) => {
                    e.stopPropagation();
                    resetFilter3("Furnished");
                  }}
                ></i>
                <p>Furnished</p>
              </li>
              <li
                onClick={() => {
                  handlieProStatus("Unfurnished");
                }}
                className={
                  activeStatus.includes("Unfurnished")
                    ? "activeFilter"
                    : "dactiveFilter"
                }
              >
                <i
                  class="fa-solid fa-xmark filterReset"
                  onClick={(e) => {
                    e.stopPropagation();
                    resetFilter3("Unfurnished");
                  }}
                ></i>
                <p>Unfurnished</p>
              </li>
              <li
                onClick={() => {
                  handlieProStatus("Under Construction");
                }}
                className={
                  activeStatus.includes("Under Construction")
                    ? "activeFilter"
                    : "dactiveFilter"
                }
              >
                <i
                  class="fa-solid fa-xmark filterReset"
                  onClick={(e) => {
                    e.stopPropagation();
                    resetFilter3("Under Construction");
                  }}
                ></i>
                <p>Under Construction</p>
              </li>

              {
             locsRes.data === undefined ? 'data not found': locsRes.data.location.map((itm) => (
                <li
                  onChange={() => {
                    handleLocation(itm.name);
                  }}
                  className={
                    itm.name === selectedLocations
                      ? "activeFilter"
                      : "dactiveFilter"
                  }
                >
                  <i
                    class="fa-solid fa-xmark filterReset"
                    onClick={(e) => {
                      e.stopPropagation();
                      resetFilter4();
                    }}
                  ></i>
                  <p>{itm.name}</p>
                </li>
              ))

            }

              {sublocationId.length === 0
                ? ""
                : locQrs.data.location.map((itm) => (
                    <li
                      className={
                        itm.name === sublocation
                          ? "activeFilter"
                          : "dactiveFilter"
                      }
                    >
                      <i
                        class="fa-solid fa-xmark filterReset"
                        onClick={(e) => {
                          e.stopPropagation();
                          resetFilter5();
                        }}
                      ></i>
                      <p>{itm.name}</p>
                    </li>
                  ))}

              {devs}
            </ul>
          </div>

        

          {/* secound filter */}
          <div className="filter">
            <div className="filterhead">
              <h6>Property</h6>
              <i class="fa-solid fa-angle-down"></i>
            </div>
            <div className="lokflilterbox">
              <div className="localityfilter">
                <input
                  type="radio"
                  name="property"
                  onChange={() => {
                    handleSaleRent("rent");
                  }}
                />
                Rent
              </div>
              <div className="localityfilter">
                <input
                  type="radio"
                  name="property"
                  onChange={() => {
                    handleSaleRent("sale");
                  }}
                />
                Sale
              </div>
            </div>
          </div>

          {/* secound filter */}
          <div className="filter">
            <div className="filterhead">
              <h6>Budget</h6>
              <i class="fa-solid fa-angle-down"></i>
            </div>

            {/* Range Slide Start             */}

            <div className="slider-container">
      <div className="slider-wrapper">
        <input
          type="range"
          id="minRange"
          className="slider"
          min="0"
          max={LABELS.length - 1}
          value={minValue}
          step="1"
        />
        <input
          type="range"
          id="maxRange"
          className="slider"
          min="0"
          max={LABELS.length - 1}
          value={maxValue}
          step="1"
        />
        <div className="slider-track"></div>
        <div className="slider-values">
          <span
            id="minValue"
            style={{ left: `calc(${minPercent}% - ${document.getElementById('minValue')?.offsetWidth / 2}px)` }}
          >
            {priceconvert(LABELS[minValue]) === 50 ? 0: priceconvert(LABELS[minValue])}
          </span>
          <span
            id="maxValue"
            style={{ left: `calc(${maxPercent}% - ${document.getElementById('maxValue')?.offsetWidth / 2}px)` }}
          >
            {priceconvert(LABELS[maxValue])}
          </span>
        </div>
      </div>
    </div><br/>
    
            {/* Range Slide End             */}
          </div>

          <div className="minmaxbudgetBox">
        <div className="minmxbgt">
            <span>Min Budget</span>
            <input type="text" placeholder="Min" name="minprice" onChange={handleChange}/>
        </div>
        <div className="minmxbgt">
            <span>Max Budget</span>
            <input type="text" placeholder="Max" name="maxprice"   onChange={handleChange}/>
        </div>
    </div>

          {/* 3rd filter */}
          <div className="filter">
            <div className="filterhead">
              <h6>No. of Bedrooms</h6>
              <i class="fa-solid fa-angle-down"></i>
            </div>
            <ul>
              <li
                onClick={() => bedroomHandle(1)}
                className={activeBHK.includes(1) ? "activeFilter" : ""}
              >
                <i class="fa-solid fa-plus"></i> 1 RK/1 BHK
              </li>
              <li
                onClick={() => bedroomHandle(2)}
                className={activeBHK.includes(2) ? "activeFilter" : ""}
              >
                <i class="fa-solid fa-plus"></i> 2 BHK
              </li>
              <li
                onClick={() => bedroomHandle(3)}
                className={activeBHK.includes(3) ? "activeFilter" : ""}
              >
                <i class="fa-solid fa-plus"></i> 3 BHK
              </li>
              <li
                onClick={() => bedroomHandle(4)}
                className={activeBHK.includes(4) ? "activeFilter" : ""}
              >
                <i class="fa-solid fa-plus"></i> 4 BHK
              </li>
              <li
                onClick={() => bedroomHandle(5)}
                className={activeBHK.includes(5) ? "activeFilter" : ""}
              >
                <i class="fa-solid fa-plus"></i> 5 BHK
              </li>
              <li
                onClick={() => bedroomHandle(6)}
                className={activeBHK.includes(6) ? "activeFilter" : ""}
              >
                <i class="fa-solid fa-plus"></i> 5 More
              </li>
            </ul>
          </div>

          {/* 4th filter */}
          <div className="filter">
            <div className="filterhead">
              <h6>Type of property</h6>
              <i class="fa-solid fa-angle-down"></i>
            </div>
            <div id="proprtyTypeFilter">
              <ul className="proprtyTypeFilter">
                {items
                  .slice(0, isShowingAll ? items.length : itemsToShowInitially)
                  .map((item, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        handlePropertyType(item);
                      }}
                      className={
                        activeProperty.includes(item) ? "activepro" : ""
                      }
                    >
                      <i class="fa-solid fa-plus"></i> {item}
                    </li>
                  ))}
              </ul>
              {items.length > itemsToShowInitially && (
                <button onClick={handleShowMoreClick}>
                  {isShowingAll ? "Show Less" : "+Show More"}
                </button>
              )}
            </div>
          </div>

          {/* 5th filter */}
          <div className="filter">
            <div className="filterhead">
              <h6>Furnishing status</h6>
              <i class="fa-solid fa-angle-down"></i>
            </div>
            <ul>
              <li
                onClick={() => {
                  handlieProStatus("Semi Furnished");
                }}
                className={
                  activeStatus.includes("Semi Furnished") ? "activestatus" : ""
                }
              >
                <i class="fa-solid fa-plus"></i> Semi furnished
              </li>
              <li
                onClick={() => {
                  handlieProStatus("Furnished");
                }}
                className={
                  activeStatus.includes("Furnished") ? "activestatus" : ""
                }
              >
                <i class="fa-solid fa-plus"></i> Furnished
              </li>
              <li
                onClick={() => {
                  handlieProStatus("Unfurnished");
                }}
                className={
                  activeStatus.includes("Unfurnished") ? "activestatus" : ""
                }
              >
                <i class="fa-solid fa-plus"></i> Unfurnished
              </li>
              <li
                onClick={() => {
                  handlieProStatus("Under Construction");
                }}
                className={
                  activeStatus.includes("Under Construction")
                    ? "activestatus"
                    : ""
                }
              >
                <i class="fa-solid fa-plus"></i> Under Construction
              </li>
            </ul>
          </div>

          {/* 6th filter */}
          <div className="filter">
            <div className="filterhead">
              <h6>Location</h6>
              <i class="fa-solid fa-angle-down"></i>
            </div>
            <div className="lokflilterbox">
              {
              
              locsRes.data === undefined ? 'data not found': locsRes.data.location.map((itm, key) => (
                <div className="localityfilter">
                  <input
                    type="radio"
                    name="location"
                    onChange={() => handleLocation(itm.name, itm.id)}
                    checked={selectedLocations.includes(itm.name)}
                  />
                  {itm.name}
                </div>
              ))
              
            }
            </div>
          </div>

          {/* 6th filter */}
          <div className="filter localities">
            <div className="filterhead">
              <h6>Localities</h6>
              <i class="fa-solid fa-angle-down"></i>
            </div>
            <div className="lokflilterbox">
              {locQrs.data && locQrs.data.location ? (
                sublocationId.length === 0 ? (
                  <p style={{ fontSize: ".7rem" }}>Select Location...</p>
                ) : (
                  locQrs.data.location.map((itms, key) => (
                    <div className="localityfilter" key={key}>
                      <input
                        type="radio"
                        name="sublocation"
                        onChange={() => handleSublok(itms.name)}
                        checked={sublocation.includes(itms.name)}
                      />
                      {itms.name}
                    </div>
                  ))
                )
              ) : (
                <p>Loading...</p> // or any other loading state
              )}
            </div>
          </div>

          {/* 8th filter */}
          <div className="filter">
            <div className="filterhead">
              <h6>Popular Developers</h6>
              <i class="fa-solid fa-angle-down"></i>
            </div>
            <div className="lokflilterbox">
              {developersToShow.length > 0 ? (
                developersToShow.map((itm) => (
                  <div className="localityfilter" key={itm.id}>
                    <input
                      type="radio"
                      onChange={() => {
                        handleDelvoper(itm.name);
                      }}
                      id={`checkbox-${itm.id}`}
                      name="devloper"
                    />
                    <label htmlFor={`checkbox-${itm.id}`}>{itm.name}</label>
                  </div>
                ))
              ) : (
                <p>No developers found</p>
              )}

              <button onClick={() => setShowAll(!showAll)}>
                {showAll ? "Show Less" : "Show All"}
              </button>
            </div>
          </div>
        </div>
        <div className="secoundBox">
          <div className="listingHead">
          <a href="../search/">All Property</a>
            <div className="secoundFilter">
              <h5>
                {sortedData.length} Results {heading === '' ? '': '| '+heading}
              </h5>
              <div className="shortby">
                <i class="fa-solid fa-filter"></i>
                <select id="price-filter" onChange={handleSort}>
                  <option value="">Relevance</option>
                  <option value="low">Price Low to High</option>
                  <option value="high">Price High to Low</option>
                </select>
              </div>
            </div>
          </div>

          {/* listing start */}
          {sortedData.length === 0 ? (
            <div className="notfounddata">
            <h4 style={{ margin: "2rem 0", textAlign: "center" }}>
              Data Not Found
            </h4>
             <a href="../search/">Show All Property</a>
            </div>
          ) : (
            sortedData.map((itm, key) => (
              <div
                className="listingContainer"
                key={key}
                data-price={itm.amount}
                data-bhk={itm.bedroom}
              >
                <div className="listingImagesBox">
                  <p
                    className={`${
                      itm.p_for === "rent"
                        ? "listRent"
                        : itm.p_for === "sale"
                        ? "listSale"
                        : ""
                    }`}
                  >
                    {itm.p_for}
                  </p>

                  <div className="listingimg">
                    {itm.gallery && itm.gallery.length > 0 && (
                      <img src={itm.gallery[0].image} alt="Image description" />
                    )}
                  </div>
                </div>

                <div className="listingContent">
                  <div className="ListContenthead">
                    <h5>{itm.name}</h5>
                    <div className="listingCtr">
                      <p>{itm.categroy_name}</p>
                    </div>
                  </div>
                  <div className="listheadLok">
                    <i class="fa-solid fa-location-dot"></i>
                    <span>{itm.location[1] + "," + itm.location[2]}</span>
                  </div>

                  <div className="listingFeature">
                    <div>
                      <i class="fa-solid fa-bed"></i>
                      <p>{itm.bedroom} BHK</p>
                    </div>
                    <div>
                      <i class="fa-solid fa-chart-area"></i>
                      <p>{itm.area} SQ. FT.</p>
                    </div>
                    <div>
                      <i class="fa-solid fa-certificate"></i>
                      <p>Verified</p>
                    </div>
                    <div>
                      <i class="fa-solid fa-chart-area"></i>
                      <p>{itm.p_for}</p>
                    </div>
                    <div>
                      <i class="fa-solid fa-chart-area"></i>
                      <p>Residential</p>
                    </div>
                  </div>

                  <p id="listingSmD">
                    {itm.n_description === null
                      ? ""
                      : itm.n_description.length > 150
                      ? itm.n_description.slice(0, 150) + "..."
                      : itm.n_description}
                  </p>

                  <div className="listingBtns">
                    <p>
                      {itm.p_type === "commercial"
                        ? "₹" + priceconvert(itm.amount) + "/- SQ. FT."
                        : priceconvert(itm.amount) === 0
                        ? "Price On Request"
                        : "₹" + priceconvert(itm.amount) + "/-"}
                    </p>
                    <div className="controlList">
                      <Link to="tel:8178749372">Booking Now</Link>
                      <Link to={"../" + itm.url}>View More</Link>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </section>
    </>
  );
};

export default Filter_List;
