//component
import React, { useEffect } from "react"
import { setForm } from "../echron/formSlice"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigationType } from 'react-router-dom'
import Banner from '../component/Banner'
import Property_slider from '../component/Property_slider'
import Location from '../component/Location'
import Property_grid from '../component/Property_grid'
import Add from '../component/Add'
import Category from '../component/Category'
import Lease from '../component/Lease'
import Years from '../component/Years'
import Slides from '../component/Slides'
import Developers from '../component/Developers'
import FootLinks from '../component/FootLinks'
import Meta from '../component/Meta'
import Loader_route from "../component/Loader_route"
import { setIsLoad, selectLoad } from "../echron/navSlice"
// import Product from '../component/Product'

function Home() {

	const dispatch = useDispatch();
	const isLoad = useSelector(selectLoad);
	const NavigationType = useNavigationType();

	useEffect(() => {
    dispatch(setForm({page: 'index'}));
    window.scrollTo(0, 0);
		if(NavigationType !== "POP") {
			dispatch(setIsLoad(true));
			setTimeout(function() {
				dispatch(setIsLoad(false));
			}, 750)
		}
  }, [dispatch]);

	document.getElementById("echron-root").classList.remove("echron-inner-header");

	return (
		<>
			<Meta follow="0" />
			<Banner />
			
			<Property_slider locationss="0" categoryss="0"  feature="active" />
			<Location order="main" limit="8" rand="yes" />
			<Property_grid order="main" limitcard="20" />
			<Add />
			<Category />
			<Lease />
			<Years />
			<Slides />
			<Developers />
			<FootLinks />
			{isLoad && <Loader_route />}
			{/* <Product /> */}
		</>
	)
}
export default Home