import { useEffect, useState } from 'react';
import { Banner1 } from "./Image";
import "../assets/css/Banner.css";
import { useGetOthersQuery, useGetSearchPostQuery } from "../echron/clientapi";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from './Loader';

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

function Banner(props) {
  const [apartmnt, setApartmnt] = useState([]);
  const { data, isError, isLoading } = useGetSearchPostQuery();
  const [result, setResult] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);
  const [show, setShow] = useState(false);
  const [echronSt, setEchronSt] = useState({ cityId: 0, cityUrl: '', localityId: 0, localityUrl: '' });
  const [headings, setHeadings] = useState(null);
  
  const navigate = useNavigate();
  
  const location = useLocation();
  const path = location.pathname;
  const searchPath = path.replace("/search/", "");
  let heading = searchPath.replace(/[+/]/g, " ");
 
  const { transcript, listening, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const [isListening, setIsListening] = useState(false);

  const startListening = () => {
    SpeechRecognition.startListening({ language: 'en-IN' });
    setIsListening(true);
  };

  const stopListening = () => {
    SpeechRecognition.stopListening();
    setIsListening(false);
  };
  

  useEffect(() => {
    if (data) {
      setApartmnt(data.post);
    }

    setHeadings(heading.trim().replace("voice&", ''));

    const voicetxt = transcript.replace(/[.,]/g, "").toLowerCase();
    setSearchTerm(voicetxt);
  }, [data, heading, transcript]);

  
 

  const [lidata, setLiData] = useState('All');

  useEffect(() => {
    const storedValue = localStorage.getItem('ctr_key');
    if (storedValue) {
      setLiData(storedValue);
    }
  }, []);

  const lifunction = (event) => {
    const newValue = event.currentTarget.textContent.trim();
    localStorage.setItem("ctr_key", newValue);
    
    setLiData(newValue);
  };

  useEffect(() => {
    const matchingTaglines = new Set();
    const cleanedSearch = searchTerm.replace(/\s+/g, '').toLowerCase();
    apartmnt.forEach((itm) => {
      if(itm.p_type.toLowerCase() === lidata.toLowerCase()){
  
      if (itm.taglines != null) {
        const taglinesArray = itm.taglines.split(',');
        taglinesArray.forEach((i) => {
          const cleanedTagline = i.trim().replace(/\s+/g, '').toLowerCase();
          if (cleanedTagline.includes(cleanedSearch)) {
            matchingTaglines.add(i.trim());
          }
        });
      }
    }else if(lidata == 'All'){
      if (itm.taglines != null) {
        const taglinesArray = itm.taglines.split(',');
        taglinesArray.forEach((i) => {
          const cleanedTagline = i.trim().replace(/\s+/g, '').toLowerCase();
          if (cleanedTagline.includes(cleanedSearch)) {
            matchingTaglines.add(i.trim());
          }
        });
      }
    }
    });
    setResult(Array.from(matchingTaglines));
  }, [searchTerm, apartmnt, lidata]);

  const handleSearch = (e) => {
   
    setSearchTerm(e.target.value);
    setHeadings(null);

  };



  const handleKeyPress = (e) => {
    if (e.key === 'ArrowDown') {
      setActiveIndex((prevIndex) => Math.min(prevIndex + 1, result.length - 1));
    } else if (e.key === 'ArrowUp') {
      setActiveIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (e.key === 'Enter') {
        
      if (activeIndex >= 0 && activeIndex < result.length) {
        const selectedItem = result[activeIndex];
        setSearchTerm(selectedItem);
        setShow(false);
      
      }
    }
  };

  const searchboardfun = () => {
    if (searchTerm.length > 1) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

 

  const sendSearchDataHandle = () => {
    const urlModify = searchTerm.replace(/ /g, "+").toLowerCase();
    const searchPath = `https://www.frontend.apartmentsingurugram.com/search${echronSt.localityId ? echronSt.localityUrl : echronSt.cityUrl}/${urlModify}`;
    window.location.href = searchPath;
  };

  useEffect(() => {
    if (!listening && transcript !== '') {
    const urlModify2 = searchTerm.replace(/ /g, "+").toLowerCase();
    const searchPath2 = `https://www.frontend.apartmentsingurugram.com/search${echronSt.localityId ? echronSt.localityUrl : echronSt.cityUrl}/voice&${urlModify2}`;
    window.location.href = searchPath2;
    }
  }, [listening, transcript]);
 

  const echronEvLocation = (id, url, type) => {
    if (type === 'city') {
      setEchronSt({ cityId: id, cityUrl: url, localityId: 0 });
    } else if (type === 'locality') {
      setEchronSt(prevState => ({
        ...prevState,
        localityId: id,
        localityUrl: url
      }));
    }
  };

  const echronEvSearch = () => {
    if (echronSt.cityId) {
      const url = echronSt.localityId ? echronSt.localityUrl : echronSt.cityUrl;
      navigate(url);
    } else {
      alert('Please Select City');
    }
  };

  if (isLoading) return <Loader />;
  if (isError) return <h1>An Error occurred: {isError.message}</h1>;


  // Speach Loader start

let delay = 200;
let dots = document.querySelectorAll('.loader .dot');
dots.forEach((dot, index) => {
  dot.style.setProperty('--delay', `${delay * index}ms`);
});
// Speach Loader End



  if (!browserSupportsSpeechRecognition) {
    return null;
  }


  return (
    <div className="EchronHomebanner">
      <div className="echron-main">
        <div className="banner-image">
          <img src={props.img2 || Banner1} alt="Banner" />
        </div>
        <div className="echron-content">
          <div className="heading">
            <h1 className="h1">Find Your Property</h1>
            <h2 className="h2">Discover the best real-estate properties in India</h2>
          </div>

          <div className="custom-select">
            <div className='filterTopbar'>
              <ul>
                <li onClick={lifunction} className={`searchCtr ${lidata === 'All' ? 'active' : ''}`}>All</li>
                <li onClick={lifunction} className={`searchCtr ${lidata === 'Residential' ? 'active' : ''}`}>Residential</li>
                <li onClick={lifunction} className={`searchCtr ${lidata === 'Commercial' ? 'active' : ''}`}>Commercial</li>
              </ul>
            </div>

            <div className='SearchFilterBox'>
              <input
                type='text'
                name="search"
                style={{ color: '#000' }}
                value={searchTerm === '' ? headings : searchTerm.toLowerCase()}
                onChange={handleSearch}
                onKeyDown={handleKeyPress}
                onInput={searchboardfun}
                placeholder={"Search "+lidata}
                className='SearchPlaceholder'
                autoComplete="off"
            
              />
             
              
              <i className={`fa-solid fa-microphone microphone ${isListening == false ? 'active' : ''}`} onClick={startListening}></i>
              
              <button
                style={{ color: '#fff', backgroundColor: 'orange', fontSize: '1.4rem' }}
                onClick={sendSearchDataHandle}
              >
                <i className="fa-solid fa-magnifying-glass"></i>
              </button>
            </div>
            {
              
              listening ?
            <div className={`speachContaner ${isListening == true ? 'active' : ''}`}>
              <h3>Listing...</h3>
              <i class="fa-solid fa-xmark speachStop" onClick={stopListening}></i>
              {listening ? (
                <div className="loader js-loader" data-delay="200">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              ) : (
                <>
                  <h4 style={{color:"#000"}}>Didn’t catch that.
              Try speaking again.</h4>
                <i
                  className={`fa-solid fa-microphone microphone1 ${isListening === false ? 'active' : ''}`}
                  onClick={startListening}
                ></i>
                </>
              )}
            </div>
            : ''
          }

          {
            result.length > 0 ?
          
            <div className={`searchboardBox ${show ? 'active' : 'dactive'}`}>
              {result.map((i, index) => (
                <div
                  key={index}
                  className={`ullistboard ${index === activeIndex ? 'active' : ''}`}
                  onClick={() => {
                    setSearchTerm(i);
                    setActiveIndex(index);
                    setShow(false);
                  }}
                >
                  <i className="fa-solid fa-magnifying-glass"></i>
                  <p>{i}</p>
                </div>
              ))}
            </div>
             :null
            }
            
          </div>
         
      

          <div className="key">
            <a href="../../commercial-properties">Discover Commercial Projects</a>
          </div>
          <div className="list">
            <ul>
              <li>
                <div className="span">42</div>
                <div className="span">Cities</div>
              </li>
              <li>
                <div className="span">5892</div>
                <div className="span">Projects</div>
              </li>
              <li>
                <div className="span">680</div>
                <div className="span">Developers</div>
              </li>
              <li>
                <div className="span">56798</div>
                <div className="span">Customers</div>
              </li>
              <li>
                <div className="span">30500Cr</div>
                <div className="span">Worth Property Sold</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
