//router
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async';

//component
import Echrontech from './screen/Echrontech'
import Home from './screen/Home'
import Page from './screen/Page'
import Error from './screen/Error';
import Search from './Search';
import Filter_List from './component/Filter_List';

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          
          <Route path="/" element={<Echrontech />}>
          <Route path="/search" element={<Search />}></Route>
          <Route path="/search/:data" element={<Search />}></Route>
            <Route index element={<Home />} />
          <Route path="404" element={<Error/> }/> 
             <Route path="*" element={<Page />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  )
}

export default App
