import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const clientApi = createApi({
  reducerPath: 'clientApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.backend.apartmentsingurugram.com/api/'
    //baseUrl: 'http://127.0.0.1:8000/api/'
  }),
  endpoints: (builder)=>({
    getCats: builder.query({
      query: (limit='') => ({
        url: 'list/category?limit='+limit,
        method: 'GET'
      })
    }),
    getDevs: builder.query({
      query: () => ({
        url: 'list/developer',
        method: 'GET'
      })
    }),
    getLocs: builder.query({
      query: (echron) => {
        
        const order = (echron.order)? echron.order : '';
        const limit = (echron.limit)? echron.limit : '';
        const rand = (echron.rand)? echron.rand : '';
        const page = (echron.page)? echron.page : 'location';
        const loc_id = (echron.loc_id)? echron.loc_id : 0;
        const cat_id = (echron.cat_id)? echron.cat_id : 0;
        return {
          url: 'list/'+page+'?order='+order+'&limit='+limit+'&rand='+rand+'&loc_id='+loc_id+'&cat_id='+cat_id,
          method: 'GET'
        };
      }
    }),
    getOthers: builder.query({
      query: (echron) => {
        return {
          url: 'other-list/'+echron.type+'/'+echron.chain+'/'+echron.parent_id,
          method: 'GET'
        };
      }
    }),
    getPosts: builder.query({
      query: (echron) => {
        var cat_id = (echron.cat_id)? echron.cat_id : '';
        var loc_id = (echron.loc_id)? echron.loc_id : '';
        var dev_id = (echron.dev_id)? echron.dev_id : '';
        var pag_id = (echron.pag_id)? echron.pag_id : '';
        var order = (echron.order)? echron.order : '';
        var feature = (echron.feature)? echron.feature : '';
        return {
          url: 'list/post?cat_id='+cat_id+'&loc_id='+loc_id+'&dev_id='+dev_id+'&pag_id='+pag_id+'&order='+order+'&feature='+feature,
          method: 'GET'
        };
      }
    }),
    getCatsPosts: builder.query({
      query: () => ({
        url: 'category/post',
        method: 'GET'
      })
    }),
    getPageDet: builder.query({
      query: (saleem) => ({
        url: 'page?url='+saleem,
        method: 'GET'
      })
    }),
    getFootLink: builder.query({
      query: () => ({
        url: 'footer',
        method: 'GET'
      })
    }),
    getNavLink: builder.query({
      query: () => ({
        url: 'nav',
        method: 'GET'
      })
    }),
    getSearchPost: builder.query({
      query: () => ({
        url: 'list/post',
        method: 'GET'
      })
    }),
    postEnqs: builder.mutation({
      query: (postNew) => {
        return {
          url: 'enquiry',
          method: 'POST',
          body: postNew
        };
      }
    }),
  })
})

export const {
  useGetCatsQuery,
  useGetLocsQuery,
  useGetDevsQuery,
  useGetOthersQuery,
  useGetPostsQuery,
  useGetCatsPostsQuery,
  useGetPageDetQuery,
  useGetNavLinkQuery,
  useGetFootLinkQuery,
  usePostEnqsMutation,
  useGetSearchPostQuery
} = clientApi