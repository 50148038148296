import { NavLink } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import 'animate.css';

function Property_card(props) {
 
  function numDifferentiation(val) {
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + ' Cr';
    }
    else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + ' Lac';
    }
    /*else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';*/
    return val;
  }

  let priceComer, bedCommer;

  if(props.p_type=='residential') {
    priceComer = ''
    bedCommer = <div className="tag">
                  <i className="fa-solid fa-bed"></i> {props.bedroom} BHK
                </div>
  }
  else {
    priceComer = '/Sq Ft'
    bedCommer = ''
  }


  // rent sale color

  const backgroundColor = props.p_for === 'rent' ? 'rgb(70 169 0)' : props.p_for === 'sale' ? 'rgb(255 0 115)' : 'white';
  const divStyle = {
    backgroundColor: backgroundColor,
   
};


  return (

    <NavLink to={props.url} className="card animate__backInUp " >
      <figure className="child-1 fig">
        {/* <Carousel indicators={false}>
          {props.gallery.map((gal) => (
            <Carousel.Item key={gal.id}>
              <img src={gal.image} alt="" />
            </Carousel.Item>
          ))}
        </Carousel> */}
         {
         props.gallery && props.gallery.length > 0 && (
          <img src={props.gallery[0].image} alt="Image description" />
          )}
      </figure>
      <div className="bed left" style={divStyle}>
        {props.p_for === 'rent' && 'Rent'}
        {props.p_for === 'sale' && 'Sale'}
      </div>
      <div className="bed">{props.categroy_name}</div>
      {/* <div className="icon">
        <i className="fa fa-heart"></i>
      </div> */}
      <div className="content">
        <div className="name">

          <div className="text-head">
          {/* <div className="text-head-left">
            {props.developer && props.developer.image ? (
              <img src={props.developer.image} alt="Developer Image" />
            ) : null}
          </div> */}

          <div className="text-head-right">
            <div className="heading">
            <div >
            <h3 className="h3"><NavLink to={props.url}>
            {
              /* props.name.length > 25 ?
              `${props.name.substring(0, 25)}...` : props.name */
              props.n_name
            }</NavLink>
            </h3>
           
          </div>
          <div className="loc">
          <small>
            {/* {props.categroy} */}
            <b>{props.location}</b>
          </small>
         
        </div>
        </div>
          
          </div>
          </div>

          <div className="short-description">
          <p>{props.n_description}</p>
          </div>

          <div className="tags">
            {bedCommer}
            <div className="tag">
              <i className="fa-solid fa-chart-area"></i> {props.area} SQ. FT.
            </div>
          </div>
        </div>
       
        {/* <div className="sqft">
          <div className="right">Rera: {props.rera? props.rera : 'Coming Soon'}</div>
        </div> */}
        <div className="price">
          {props.amount ? <h5><i className="fa fa-inr"></i> {numDifferentiation(props.amount)}{priceComer}</h5> : "*Price on Request*"}
          <div className="button">
            <NavLink to={`../${props.url}`}>View Details</NavLink>
            
          </div>
        </div>
      </div>
    </NavLink>
  );
}
export default Property_card;
